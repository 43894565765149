.navbars {
    header: 10px;
    background-color:rgb(54, 53, 53) ;
    color:whitesmoke;
    margin-bottom: 15px;
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg {
    background-image: url("./tutoriallogo.png");
    height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}