.App {
  text-align: center;
}

.h1head {

  line-height: 48px;
  font-size: 40px;
  font-family: medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 400;
  margin: 0;
  box-sizing: inherit;
  letter-spacing: 0;
  font-style: normal;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  w-ebkit-font-smoothing: antialiased;
  box-sizing: inherit;
}


.h2head {

line-height: 32px;
font-size: 20px;
font-weight: 300;
color: rgba(0, 0, 0, 0.54);
font-family: medium-content-sans-serif-font, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
display: block;
 
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
   
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
